import React, { useEffect } from 'react';
import $ from 'jquery';
import './clientFilter.js';

// const filterClients = () => {
//     // var $container = $('.awesome-project-content');
//     // var pro_menu = $('.project-menu li a');
//     // var pro_menu_active = $('.project-menu li a.active');
//     // pro_menu_active.removeClass('active');
//     // $(this).addClass('active');
//     // var selector = $(this).attr('data-filter');
//     // $container.isotope({
//     //     filter: selector,
//     //     animationOptions: {
//     //         duration: 750,
//     //         easing: 'linear',
//     //         queue: false
//     //     }
//     // });
//     // return false;

//     // alert("Button clicked!")
    
// }

// var $container = $('.awesome-project-content');

const OurClients = () => {

    // useEffect(() => {
    //     $(window).on("load", function() {
    //         var $container = $('.awesome-project-content');
    //         $container.isotope({
    //           filter: '*',
    //           animationOptions: {
    //             duration: 750,
    //             easing: 'linear',
    //             queue: false
    //           }
    //         });
    //         // var pro_menu = $('.project-menu li a');
    //         // pro_menu.on("click", function() {
    //         //   var pro_menu_active = $('.project-menu li a.active');
    //         //   pro_menu_active.removeClass('active');
    //         //   $(this).addClass('active');
    //         //   var selector = $(this).attr('data-filter');
    //         //   $container.isotope({
    //         //     filter: selector,
    //         //     animationOptions: {
    //         //       duration: 750,
    //         //       easing: 'linear',
    //         //       queue: false
    //         //     }
    //         //   });
    //         //   return false;
    //         // });

    //     });
        
    //     // alert("Component loaded!");
    // });

    return(
        <div id="portfolio" className="portfolio-area area-padding fix">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="section-headline text-center">
                    <h2>
                        Our Clients<br />
                        <span style={{ fontSize: 15 }}>We have had a clientele that cuts across all spheres of the legal practice, some of whom are: -</span>
                    </h2>
                    </div>
                </div>
                </div>
                <div className="row">
                {/* Start Portfolio -page */}
                {/* <div className="awesome-project-1 fix">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="awesome-menu ">
                        <ul className="project-menu">
                        <li>
                            <a href="#" className="active" data-filter="*" onClick={filterClients}>All</a>
                        </li>
                        <li>
                            <a href="#" data-filter=".design" onClick={filterClients}>Companies & Associations</a>
                        </li>
                        <li>
                            <a href="#" data-filter=".development" onClick={filterClients}>Development & NGOs</a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div> */}
                <div className="awesome-project-content">
                    {/* single-awesome-project start */}
                    {/* <div className='row'> */}
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <ul>
                                <li className='design'>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Post Bank, Uganda</span>
                                </li>
                                <li className='design'>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>United Nations Development Programme (UNDP)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>CECEC Nanjing Electrical Company Limited, China</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Amazing Grace of Christ Ministries (Uganda)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Network for Public Interest Lawyers (NETPIL), Uganda</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Hope International (USA)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>ZEE Group Limited</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>ACTED (France)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Fairland University</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Eastern Logistics</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Uganda Christian Lawyers Fraternity</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Mbale Importers and Exporters</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Leeway Services Ltd</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>CNS Technologies</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Commission International (Uganda)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>The Conservative Party, Uganda</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>West Mengo Growers Co-operative Union</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Kasana Kaddu Coffee Factory, Luwero</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Brass for Africa</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>The Management UMEA Primary School</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Human Rights Network for Journalists (HRNJ-U)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>Association of Uganda Tour Operators (AUTO)</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span>And others</span>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="col-md-3 col-sm-3 col-xs-12">
                            <ul>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Home</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>About Us</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Services and Portfolio</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>FAQs</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Contact Us</span>
                                </li>
                            </ul>
                        </div> */}
                        {/* <div className="col-md-4 col-sm-4 col-xs-12">
                            <ul>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Home</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>About Us</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Services and Portfolio</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>FAQs</span>
                                </li>
                                <li>
                                    <i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Contact Us</span>
                                </li>
                            </ul>
                        </div> */}
                    {/* </div> */}
                    {/* single-awesome-project end */}
                </div>
                </div>
            </div>
        </div>
    );
}

export default OurClients;