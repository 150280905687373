import React, { useEffect } from 'react';
// // import $ from 'jquery';
// // import './bannerScript.js';
// import './jquery.nivo.slider.js';
// import BannerImage from './../../resources/images/hero/g1.jpg';
import './banner.css';

const Banner = () => {

//     // useEffect(() => {
        
//     // });

    return(
//         <div id="home" className="slider-area">
//             <div className="bend niceties preview-2">
//                 <div id="ensign-nivoslider" className="slides">
//                     <img src={BannerImage} alt="Walyemera & Co. Advocates" title="#slider-direction-1" />
//                 </div>

//                 {/* direction 1 */}
//                 {/* <div id="slider-direction-1" className="slider-direction slider-one">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12 col-sm-12 col-xs-12">
//                                 <div className="slider-content">
                                    
//                                     <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
//                                         <h1 className="title2">Walyemera & Co. Advocates</h1>
//                                     </div>
                                    
//                                     <div className="layer-1-1 hidden-xs wow slideInDown" data-wow-duration="2s" data-wow-delay=".2s">
//                                         <h2 className="title1">We provide Quality Legal Services to Society with Honesty and Integrity</h2>
//                                     </div>
                                    
//                                     <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
//                                         <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
//                                         <a className="ready-btn page-scroll" href="#about">Learn More</a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div> */}
//             </div>
//         </div>
            <div className="banner-area">
                <div className="banner-contents">
                    <h1>Walyemera & Co. Advocates</h1>
                    <p><b>We provide Quality Legal Services to Society with Honesty and Integrity</b></p>
                    {/* <button className="btn-bgstroke">Call To Action</button> */}
                </div>
            </div>
        );
}

export default Banner;




