import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <footer>
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="footer-content">
                                <div className="footer-head">
                                    <div className="footer-logo">
                                        <h2><span>WCA</span></h2>
                                    </div>
                                    <p>We provide Quality Legal Services to Society with Honesty and Integrity.</p>
                                    <div className="footer-icons">
                                        <ul>
                                            <li>
                                                <a href="https://twitter.com/wcadvocates"><i className="fa fa-twitter"></i>{/*WCAdvocates*/}</a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/pages/category/Lawyer---Law-Firm/Walyemera-Co-Advocates-406789419838981/"><i className="fa fa-facebook"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end single footer */}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="footer-content">
                                <div className="footer-head">
                                    <h4>Information</h4>
                                    <p>
                                        Reach out to us.
                                    </p>
                                    <div className="footer-contacts">
                                        <p><span>Tel:</span> (+256) 393194148</p>
                                        <p><span>Email:</span> inquiries@wca.ug</p>
                                        <p><span>Working Hours:</span> 9am-5pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end single footer */}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="footer-content">
                                <div className="footer-head">
                                    <h4>Links</h4>
                                    <ul>
                                        <li>
                                            <Link to="/" style={{ textDecoration: "none", fontColor: "#fff" }}><i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Home</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/about-us" style={{ textDecoration: "none", fontColor: "#fff" }}><i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>About Us</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/our-services" style={{ textDecoration: "none", fontColor: "#fff" }}><i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Services and Portfolio</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/inquiries" style={{ textDecoration: "none", fontColor: "#fff" }}><i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>FAQs</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us" style={{ textDecoration: "none", fontColor: "#fff" }}><i class="fa fa-check" style={{ color: "#088df9" }}></i> <span style={{color: "#000"}}>Contact Us</span></Link>
                                        </li>
                                    </ul>
                                    {/* <div className="flicker-img">
                                        <a href="#"><img src="img/portfolio/1.jpg" alt="" /></a>
                                        <a href="#"><img src="img/portfolio/2.jpg" alt="" /></a>
                                        <a href="#"><img src="img/portfolio/3.jpg" alt="" /></a>
                                        <a href="#"><img src="img/portfolio/4.jpg" alt="" /></a>
                                        <a href="#"><img src="img/portfolio/5.jpg" alt="" /></a>
                                        <a href="#"><img src="img/portfolio/6.jpg" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-area-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="copyright text-center">
                                <p>
                                    {/* &copy; Copyright */}<strong>Walyemera & Co. Advocates</strong>.{/* All Rights Reserved. */}
                                </p>
                            </div>
                            <div className="credits">
                                {/* All the links in the footer should remain intact.
                                You can delete the links only if you purchased the pro version.
                                Licensing information: https://bootstrapmade.com/license/
                                Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=eBusiness
                                */}
                                <a href="https://www.wca.ug">www.wca.ug</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;