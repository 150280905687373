import React, {useEffect} from 'react';
import $ from 'jquery';
import InquiryForm from '../components/contact/forms/InquiryForm';
// import '../components/faqs/accordion.css';
import '../components/faqs/faq.js';
// import FrequentQuestion from '../components/faqs/FrequentQuestion';
// import FrequentlyAskedQuestions from '../components/faqs/FrequentlyAskedQuestions';

// var panel_test = $('.panel-heading a');

// const clickFaq = () => {

//     // var panel_test = $('.panel-heading a');
//     panel_test.removeClass('active');
//     $(this).addClass('active');
// }

const InquiriesPage = () => {

    useEffect(() => {
        
        // COLLAPSE PUBLIC CLASS DEFINITION
    // ================================
      
    var Collapse = function (element, options) {
        this.$element      = $(element)
        this.options       = $.extend({}, Collapse.DEFAULTS, options)
        this.$trigger      = $('[data-toggle="collapse"][href="#' + element.id + '"],' +
                               '[data-toggle="collapse"][data-target="#' + element.id + '"]')
        this.transitioning = null
    
        if (this.options.parent) {
          this.$parent = this.getParent()
        } else {
          this.addAriaAndCollapsedClass(this.$element, this.$trigger)
        }
    
        if (this.options.toggle) this.toggle()
      }
    
      Collapse.VERSION  = '3.3.7'
    
      Collapse.TRANSITION_DURATION = 350
    
      Collapse.DEFAULTS = {
        toggle: true
      }
    
      Collapse.prototype.dimension = function () {
        var hasWidth = this.$element.hasClass('width')
        return hasWidth ? 'width' : 'height'
      }
    
      Collapse.prototype.show = function () {
        if (this.transitioning || this.$element.hasClass('in')) return
    
        var activesData
        var actives = this.$parent && this.$parent.children('.panel').children('.in, .collapsing')
    
        if (actives && actives.length) {
          activesData = actives.data('bs.collapse')
          if (activesData && activesData.transitioning) return
        }
    
        var startEvent = $.Event('show.bs.collapse')
        this.$element.trigger(startEvent)
        if (startEvent.isDefaultPrevented()) return
    
        if (actives && actives.length) {
          Plugin.call(actives, 'hide')
          activesData || actives.data('bs.collapse', null)
        }
    
        var dimension = this.dimension()
    
        this.$element
          .removeClass('collapse')
          .addClass('collapsing')[dimension](0)
          .attr('aria-expanded', true)
    
        this.$trigger
          .removeClass('collapsed')
          .attr('aria-expanded', true)
    
        this.transitioning = 1
    
        var complete = function () {
          this.$element
            .removeClass('collapsing')
            .addClass('collapse in')[dimension]('')
          this.transitioning = 0
          this.$element
            .trigger('shown.bs.collapse')
        }
    
        if (!$.support.transition) return complete.call(this)
    
        var scrollSize = $.camelCase(['scroll', dimension].join('-'))
    
        this.$element
          .one('bsTransitionEnd', $.proxy(complete, this))
          .emulateTransitionEnd(Collapse.TRANSITION_DURATION)[dimension](this.$element[0][scrollSize])
      }
    
      Collapse.prototype.hide = function () {
        if (this.transitioning || !this.$element.hasClass('in')) return
    
        var startEvent = $.Event('hide.bs.collapse')
        this.$element.trigger(startEvent)
        if (startEvent.isDefaultPrevented()) return
    
        var dimension = this.dimension()
    
      //   this.$element[dimension](this.$element[dimension]())[0].offsetHeight
    
        this.$element
          .addClass('collapsing')
          .removeClass('collapse in')
          .attr('aria-expanded', false)
    
        this.$trigger
          .addClass('collapsed')
          .attr('aria-expanded', false)
    
        this.transitioning = 1
    
        var complete = function () {
          this.transitioning = 0
          this.$element
            .removeClass('collapsing')
            .addClass('collapse')
            .trigger('hidden.bs.collapse')
        }
    
        if (!$.support.transition) return complete.call(this)
    
        this.$element
          [dimension](0)
          .one('bsTransitionEnd', $.proxy(complete, this))
          .emulateTransitionEnd(Collapse.TRANSITION_DURATION)
      }
    
      Collapse.prototype.toggle = function () {
        this[this.$element.hasClass('in') ? 'hide' : 'show']()
      }
    
      Collapse.prototype.getParent = function () {
        return $(this.options.parent)
          .find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]')
          .each($.proxy(function (i, element) {
            var $element = $(element)
            this.addAriaAndCollapsedClass(getTargetFromTrigger($element), $element)
          }, this))
          .end()
      }
    
      Collapse.prototype.addAriaAndCollapsedClass = function ($element, $trigger) {
        var isOpen = $element.hasClass('in')
    
        $element.attr('aria-expanded', isOpen)
        $trigger
          .toggleClass('collapsed', !isOpen)
          .attr('aria-expanded', isOpen)
      }
    
      function getTargetFromTrigger($trigger) {
        var href
        var target = $trigger.attr('data-target')
          || (href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '') // strip for ie7
    
        return $(target)
      }
    
    
      // COLLAPSE PLUGIN DEFINITION
      // ==========================
    
      function Plugin(option) {
        return this.each(function () {
          var $this   = $(this)
          var data    = $this.data('bs.collapse')
          var options = $.extend({}, Collapse.DEFAULTS, $this.data(), typeof option == 'object' && option)
    
          if (!data && options.toggle && /show|hide/.test(option)) options.toggle = false
          if (!data) $this.data('bs.collapse', (data = new Collapse(this, options)))
          if (typeof option == 'string') data[option]()
        })
      }
    
      var old = $.fn.collapse
    
      $.fn.collapse             = Plugin
      $.fn.collapse.Constructor = Collapse
    
    
      // COLLAPSE NO CONFLICT
      // ====================
    
      $.fn.collapse.noConflict = function () {
        $.fn.collapse = old
        return this
      }
    
    
      // COLLAPSE DATA-API
      // =================
    
      $(document).on('click.bs.collapse.data-api', '[data-toggle="collapse"]', function (e) {
        var $this   = $(this)
    
        if (!$this.attr('data-target')) e.preventDefault()
    
        var $target = getTargetFromTrigger($this)
        var data    = $target.data('bs.collapse')
        var option  = data ? 'toggle' : $this.data()
    
        Plugin.call($target, option)
      })

    });

    return(
        <div>
            <div className="faq-area area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>
                                  Frequently Asked Questions.<br />
                                  <span style={{ fontSize: 15 }}>Here are some of the questions that we expect you to ask.</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="faq-details">
                                <div className="panel-group" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading"/* onClick={clickFaq}*/>
                                            <h4 className="check-title">
                                                <a data-toggle="collapse" className="active" data-parent="#accordion" href="#check1">
                                                    <span className="acc-icons"></span>What is WCA in full?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="check1" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <p>
                                                    WCA stands for Walyemera & Co. Advocates.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Panel Default */}
                                    <div className="panel panel-default">
                                        <div className="panel-heading"/* onClick={clickFaq}*/>
                                            <h4 className="check-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#check2">
                                                    <span className="acc-icons"></span>Will I be expected to pay for my first interaction with Walyemera & Co. Advocates?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="check2" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>
                                                    No, we do not charge our clients for our initial / first interaction with them.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Panel Default */}
                                    {/* Panel Default */}
                                    <div className="panel panel-default">
                                        <div className="panel-heading"/* onClick={clickFaq}*/>
                                            <h4 className="check-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#check3">
                                                    <span className="acc-icons"></span>In which regions / areas does WCA operate?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="check3" className="panel-collapse collapse ">
                                            <div className="panel-body">
                                                <p>
                                                    Our services are available anywhere and everywhere around the world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Panel Default */}
                                    {/* Panel Default */}
                                    {/* <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="check-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#check4">
                                                    <span className="acc-icons"></span>Maiores alias accusamus
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="check4" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>
                                                    Redug Lefes dolor sit amet, consectetur adipisicing elit. Aspernatur, tempore, commodi quas mollitia dolore magnam quidem repellat, culpa voluptates laboriosam maiores alias accusamus recusandae vero aperiam sint nulla beatae eos.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* End Panel Default */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end Row */}
                </div>
            </div>
            <div id="contact" className="contact-area">
                <div className="contact-inner area-padding">
                    <div className="contact-overly"></div>
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="section-headline text-center">
                                    <h4>Were you able to have your question answered from the above FAQs? If not please ask your question using the form below.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <InquiryForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InquiriesPage;