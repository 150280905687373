import React from 'react';
import TeamMember from './TeamMember';
import { TeamMembers } from './TeamMembers';

const OurTeam = () => {
    return(
        <div id="team" className="our-team-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <h2>
                                Our special Team<br />
                                <span style={{ fontSize: 15 }}>Meet our team.</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="team-top">
                    {TeamMembers.map(value => (
                        <TeamMember
                            id={value.keyvalue}
                            image={value.image}
                            name={value.name}
                            title={value.position}
                        />
                    ))}
                        <TeamMember
                            id="other-team-members" //0MWC
                            image="https://www.wca.ug/image/team/unavailableimage.png"
                            name="Other Members"
                            title="Associate"
                            details={<><p>Not there.</p></>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurTeam;