export let TeamMembers = [{
    id: "KWC001",
    keyvalue: "daniel-walyemera",
    image: "https://www.wca.ug/image/team/daniel-walyemera.png",
    name: "Dr. Daniel Walyemera",
	position: "Managing Partner",
    others: "Advocate | Commissioner | Notary Public",
    firmemail: "dwalyemera@wca.ug",
    peremail: "dwmasumba@gmail.com",
    comcell: "+256 393194148",
    personal: "+256 758448598",
    desc: [<>
        <p>Dr. Walyemera is a Managing Partner of the firm. He holds a Doctorate of Laws (LLD) from the University of Western Cape, Cape Town, South Africa. He also holds a Master of Laws (LLM) from Makerere University, Kampala, Uganda. Dr. Walyemera also holds Bachelor of Laws (LLB) from the Islamic University in Uganda.  Dr. Walyemera also holds a Diploma in Law and a Post Graduate Diploma in Legal Practice from the Law Development Centre. In 2018, Dr. Walyemera was also a Doctoral Researcher at the University of Pretoria, South Africa.  Dr. Walyemera is the immediate past Dean of the Faculty of Law, Cavendish University, Uganda. He is currently a Senior Law Lecturer at Cavendish University in Uganda.</p>
        <p>Dr. Walyemera has also worked with various law firms for over twenty-one (21) years. He is on the list of the practicing Counsels of the International Criminal Court and is also currently one of the two Ugandan Lawyers on the list of counsel of the African Court on Human and People’s Rights, located in Arusha, Tanzania. He has also done consultancy work for several organizations, including the United Nations Development Programme. His areas of practice are commercial transactions, human rights and constitutional law. His research interests include civil and political rights especially media freedom; Peaceful assembly and police use of force; corruption and anti-corruption, and electoral democracy.</p>
        <div>
            <div className="profile-section">MEMBERSHIP IN PROFESSIONAL SOCIETIES.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Practicing Counsel of the International Criminal Court</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Practicing Counsel of the African Court on Human and People’s Rights, located in Arusha, Tanzania</li>
            </ul>
            <div className="profile-section">PROFESSIONAL QUALIFICATIONS.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Doctorate of Laws (LLD) from the University of Western Cape, Cape Town, South Africa</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Master`s Degree in Law (LLM) from Makerere University, Kampala, Uganda</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Bachelor of Laws (LLB) from the Islamic University, Uganda</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Diploma in Law and a Post Graduate Diploma in Legal Practice from the Law Development Centre</li>
            </ul>
        </div>
    </>]
},{
    id: "KWC002",
    keyvalue: "rukia-adam",
    image: "https://www.wca.ug/image/team/rukia-adam.png",
    name: "Rukia Adam",
	position: "Advocate",
    others: "LLB | Dip LP | MBA | ICSA Grad",
    firmemail: "radam@wca.ug",
    peremail: "inquiries@wca.ug",
    comcell: "+256 393194148",
    personal: "+256 758554388",
    desc: [<>
        <p>Rukia is an advocate on the Walyemera & Co. Advocates Corporate team with over 15 years’ experience in investment advisory, multi-jurisdictional commercial transactions cutting across corporate M&A, Private Equity, PPPs, Energy, Projects & Infrastructure, land transactions, general corporate commercial, governance and regulatory advisory.</p>
        <p>She holds a 1st Class Master’s Degree in Business and Administration from the Eastern and Southern Africa Management Institute- Arusha (ESAMI), a law degree from Makerere University (Upper Second) and a Post Graduate Diploma in Legal Practice from the Law Development Center. She is also an ICSA graduate from the Chartered Governance Institute, UK.</p>
        <p>She also routinely handles legal due diligences, provides advice on corporate transactional structuring, negotiates local and cross border transactional agreements, commercial operational agreements. She has an impressive knowledge of the local regulatory and licensing frameworks and issues affecting local and foreign investors.</p>
        <div>
            <div className="profile-section">MEMBERSHIP IN PROFESSIONAL SOCIETIES.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Advocate, High Court of Uganda</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Member, Uganda Law Society</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Member, East Africa Law Society (EALS)</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Graduate Member, ICSA</li>
            </ul>
            <div className="profile-section">PROFESSIONAL QUALIFICATIONS.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Master’s Degree in Business and Administration from the Eastern and Southern Africa Management Institute- Arusha, Tanzania</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Diploma in Legal Practice (Bar Course), Law Development Centre, Uganda</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> LL.B (Hons.), Makerere University, Uganda</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Graduate, Institute of Chartered Secretaries and Administrators, England</li>
            </ul>
            <div className="profile-section">AREAS OF EXPERTISE.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Corporate and investment advisory</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Governance</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Corporate and Project Finance</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Taxation</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Energy and Natural resources</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Projects and Infrastructure</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Private Equity</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Banking</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Land transaction advisory</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> Employment and Labour relations</li>
            </ul>
            <div className="profile-section">CAREER SUMMARY.</div>
            <ul>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> <b>2022 - date: </b>Advocate, Walyemera & Co. Advocates.</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> <b>2021 - 2022: </b>Acting Corporation Secretary, Uganda Investment Authority.</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> <b>2014 - 2021: </b>Senior Investment Executive- Legal, Uganda Investment Authority.</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> <b>2010 - 2014: </b>State Attorney, Ministry of Justice and Constitutional Affairs, Uganda.</li>
                <li><i class="fa fa-check" style={{ color: "#088df9" }}></i> <b>2008 - 2010: </b>Legal Officer, Sugar Corporation of Uganda Ltd.</li>
            </ul>
            {/* <div className="timeline-section">
                <div className="timeline-centered">
                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-1">
                                <i className="icon-pen2"></i>
                            </div>
                            <div className="timeline-label">
                                <h2><b>Walyemera & Co. Advocates</b> <span>2022 - date</span></h2>
                                <p><b>Position:</b> Advocate</p>
                            </div>
                        </div>
                    </article>
                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-1">
                                <i className="icon-pen2"></i>
                            </div>
                            <div className="timeline-label">
                                <h2><b>Uganda Investment Authority</b> <span>2021 - 2022</span></h2>
                                <p><b>Position:</b> Acting Corporation Secretary</p>
                            </div>
                        </div>
                    </article>
                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-1">
                                <i className="icon-pen2"></i>
                            </div>
                            <div className="timeline-label">
                                <h2><b>Uganda Investment Authority</b> <span>2014 - 2021</span></h2>
                                <p><b>Position:</b> Senior Investment Executive - Legal</p>
                            </div>
                        </div>
                    </article>
                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-1">
                                <i className="icon-pen2"></i>
                            </div>
                            <div className="timeline-label">
                                <h2><b>Ministry of Justice and Constitutional Affairs, Uganda</b> <span>2010 - 2014</span></h2>
                                <p><b>Position:</b> State Attorney</p>
                            </div>
                        </div>
                    </article>
                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-1">
                                <i className="icon-pen2"></i>
                            </div>
                            <div className="timeline-label">
                                <h2><b>Sugar Corporation of Uganda Ltd.</b> <span>2008 - 2010</span></h2>
                                <p><b>Position:</b> Legal Officer</p>
                            </div>
                        </div>
                    </article>
                    <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-none">
                            </div>
                        </div>
                    </article>
                </div>
            </div> */}
        </div>
    </>]
},{
    id: "KWC003",
    keyvalue: "zeridah-kaitesi",
    image: "https://www.wca.ug/image/team/zeridah-kaitesi.png",
    name: "Zeridah Kaitesi",
	position: "Associate",
    others: "",
    firmemail: "zkaitesi@wca.ug",
    peremail: "inquiries@wca.ug",
    comcell: "+256 393194148",
    personal: "+256 784320851",
    desc: [<>
        <p>She is the Legal Associate of the Firm. She holds a Bachelor of Laws from Makerere University. She also holds a Diploma in Legal Practice from The Institute of Legal Practice and Development, Kigali Rwanda. Zeridah handles our cross-border business in Rwanda. Her areas of specialization are family law, commercial transactions, international trade and business, among others.</p>
    </>]
}]

// module.exports = {
//     TeamMembers
// }