import React from 'react';
import ContactForm from './forms/ContactForm';
import './css/contactus.css';

const ContactUs = () => {
    return(
        <div id="contact" className="contact-area">
            <div className="contact-inner area-padding">
                <div className="contact-overly"></div>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>Contact us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Start contact icon column */}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                <i className="fa fa-mobile"></i>
                                <p>
                                    Call: <a className='contactus-links' href="tel://+256 393194148">(+256) 393194148</a><br />
                                    <span>Monday-Friday (9am-5pm)</span>
                                </p>
                                </div>
                            </div>
                        </div>
                        {/* Start contact icon column */}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                <i className="fa fa-envelope-o"></i>
                                <p>
                                    Email: <a className='contactus-links' href="mailto:inquiries@wca.ug">inquiries@wca.ug</a><br />
                                    P.0. Box 11890, Kampala<br />
                                    <span>Web: www.wca.ug</span>
                                </p>
                                </div>
                            </div>
                        </div>
                        {/* Start contact icon column */}
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                <i className="fa fa-map-marker"></i>
                                <p>
                                    Plot 472, Gabba Road,<br />
                                    Suite 11, 1st Floor, Millennium House,<br />
                                    <span>Nsambya, Kampala City</span>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Start Google Map */}
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            {/* Start Map */}
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22864.11283411948!2d-73.96468908098944!3d40.630720240038435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbg!4v1540447494452" width="100%" height="380" frameborder="0" style="border:0" allowfullscreen></iframe> */}
                            {/* End Map */}
                        </div>
                        {/* End Google Map */}
                        {/* Start  contact */}
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <ContactForm />
                        </div>
                        {/* End Left contact */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;