import React from 'react';
import { Container } from 'react-bootstrap';
// Imprting page sections.
import Banner from '../components/banner/Banner';
import AboutSummary from '../components/about/AboutSummary';
import OurServices from '../components/services/OurServices';
import OurClients from '../components/clients/OurClients';
import ContactUs from '../components/contact/ContactUs';

const HomePage = () => {
    return(
        <div>
            <Banner />
            <AboutSummary />
            <OurServices />
            <OurClients />
            <ContactUs />
        </div>
    );
}

export default HomePage;