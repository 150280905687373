import React from 'react';
import { Link } from 'react-router-dom';
import ServiceItem from './ServiceItem';

const OurServices = () => {
    return(
        <div id="services" className="services-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline services-head text-center">
                        <h2>Our Services</h2>
                        {/* <p>What We Offer.</p> */}
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="services-contents">
                        <p>
                            We offer a wide and full range of legal and consultancy services, including but not limited to, planning and implementing business with individuals, companies, government institutions and United Nations bodies.<br />
                            The Lawyers of the firm are experts in many areas of the law. They engage in various areas of practice including:
                        </p>
                        <ServiceItem
                            icon="fa-gavel"
                            name="Civil and Criminal Litigation" />
                        <ServiceItem
                            icon="fa-money"
                            name="Commercial Law" />
                        <ServiceItem
                            icon="fa-globe"
                            name="Land Transactions" />
                        <ServiceItem
                            icon="fa-camera-retro"
                            name="Arbitration, Mediation and Conciliation" />
                        <ServiceItem
                            icon="fa-bar-chart"
                            name="Property Management" />
                        <ServiceItem
                            icon="fa-hands-holding-child"
                            name="Human rights defense and advocacy" />
                        <ServiceItem
                            icon="fa-legal"
                            name="Constitutional law and practice" />
                        <ServiceItem
                            icon="fa-code"
                            name="Public Interest Litigation" />
                    </div>
                </div>
                <div className='row text-center'>
                    <p>
                        <span style={{ fontSize: 15 , fontWeight: 'bold' }}>Do you happen to have any questions about our services, please feel fee to ask below.</span>
                    </p>
                    <div className="contact-form">
                        <Link to="/inquiries"><div className="text-center"><button type="submit">Ask a Question</button></div></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;