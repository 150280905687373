import React from 'react';
import { Link } from 'react-router-dom';

const TeamMember = (props) => {
    return(
        <div className="col-md-3 col-sm-3 col-xs-12">
            <div className="single-team-member">
                <div className="team-img">
                    <Link to={"/about-us/team/"+props.id}>
                        <img src={props.image} alt={props.name} />
                    </Link>
                    <div className="team-social-icon text-center">
                        <Link to={"/about-us/team/"+props.id}><button type="submit" className="learn-more">Learn More</button></Link>
                        {/* <ul>
                            <li>
                                <a href="#">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul> */}
                    </div>
                </div>
                <div className="team-content text-center">
                    <h4>{props.name}</h4>
                    <p>{props.title}</p>
                </div>
            </div>
        </div>
    );
}

export default TeamMember;