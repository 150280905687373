import React from 'react';
import { useParams } from 'react-router-dom';
// import TeamMemberProfile from '../../components/about/team/TeamMemberProfile';
import TeamMember from '../../components/about/team/TeamMember';
import { TeamMembers } from '../../components/about/team/TeamMembers';
import '../../components/about/team/team.css';

const TeamMemberProfilePage = () => {

    const { keyId } = useParams();
    const profile = TeamMembers.filter(partner => partner.keyvalue === keyId);
    const notprofile = TeamMembers.filter(partner => partner.keyvalue !== keyId);
    // console.log(notprofile);

    return(
        <div>
            <div id="about" className="about-area area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>
                                    <span style={{ color: "#088df9" }}>{profile[0].name}</span><br />
                                    <span style={{ fontSize: 27 }}>{profile[0].position}</span><br />
                                    <span style={{ fontSize: 17 }}>{profile[0].others}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                        <article class="blog-post-wrapper">
                            <div class="row" style={{ textAlign: "center" }}>
                                <div class="col-md-5 col-sm-5 col-xs-12">
                                    <div class="post-thumbnail">
                                        <img src={profile[0].image} alt={keyId} style={{ height: 350 }} />
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-5 col-xs-12" style={{ alignItems: "center" }}>
                                    <div className="footer-content">
                                        <div className="footer-head">
                                            <h4>Personal Information</h4>
                                            <div className="footer-contacts" style={{ textAlign: "left" }}>
                                                <p className='profil' style={{ fontSize: 17 }}><span>Email:</span> {profile[0].firmemail}</p>
                                                <p className='profil' style={{ fontSize: 17 }}><span>Email:</span> {profile[0].peremail}</p>
                                                <p className='profil' style={{ fontSize: 17 }}><span>Tel:</span> {profile[0].personal}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="post-information">
                            <h2>Profile:</h2>
                            <div class="entry-meta">
                                {/* <span class="author-meta"><i class="fa fa-user"></i> <a href="#">admin</a></span>
                                <span><i class="fa fa-clock-o"></i> march 28, 2016</span>
                                <span class="tag-meta">
                                                            <i class="fa fa-folder-o"></i>
                                                            <a href="#">painting</a>,
                                                            <a href="#">work</a>
                                                        </span>
                                <span>
                                                            <i class="fa fa-tags"></i>
                                                            <a href="#">tools</a>,
                                                            <a href="#"> Humer</a>,
                                                            <a href="#">House</a>
                                                        </span>
                                <span><i class="fa fa-comments-o"></i> <a href="#">6 comments</a></span> */}
                            </div>
                            <div class="entry-content">
                                {profile[0].desc}
                                {/* <p>Aliquam et metus pharetra, bibendum massa nec, fermentum odio. Nunc id leo ultrices, mollis ligula in, finibus tortor. Mauris eu dui ut lectus fermentum eleifend. Pellentesque faucibus sem ante, non malesuada odio varius nec. Suspendisse
                                potenti. Proin consectetur aliquam odio nec fringilla. Sed interdum at justo in efficitur. Vivamus gravida volutpat sodales. Fusce ornare sit amet ligula condimentum sagittis.</p>
                                <blockquote>
                                <p>Quisque semper nunc vitae erat pellentesque, ac placerat arcu consectetur. In venenatis elit ac ultrices convallis. Duis est nisi, tincidunt ac urna sed, cursus blandit lectus. In ullamcorper sit amet ligula ut eleifend. Proin dictum
                                    tempor ligula, ac feugiat metus. Sed finibus tortor eu scelerisque scelerisque.</p>
                                </blockquote>
                                <p>Aenean et tempor eros, vitae sollicitudin velit. Etiam varius enim nec quam tempor, sed efficitur ex ultrices. Phasellus pretium est vel dui vestibulum condimentum. Aenean nec suscipit nibh. Phasellus nec lacus id arcu facilisis elementum.
                                Curabitur lobortis, elit ut elementum congue, erat ex bibendum odio, nec iaculis lacus sem non lorem. Duis suscipit metus ante, sed convallis quam posuere quis. Ut tincidunt eleifend odio, ac fringilla mi vehicula nec. Nunc vitae
                                lacus eget lectus imperdiet tempus sed in dui. Nam molestie magna at risus consectetur, placerat suscipit justo dignissim. Sed vitae fringilla enim, nec ullamcorper arcu.</p> */}
                            </div>
                            </div>
                        </article>
                        </div>
                    </div>
                </div>
            </div>
            <div id="team" className="our-team-area area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>
                                    Other Team Members<br />
                                    <span style={{ fontSize: 15 }}>Meet the rest of our team.</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="team-top" style={{ alignItems: "center" }}>
                        {notprofile.map(value => (
                            <TeamMember
                                id={value.keyvalue}
                                image={value.image}
                                name={value.name}
                                title={value.position}
                            />
                        ))}
                            <TeamMember
                                id="other-team-members" //0MWC
                                image="https://www.wca.ug/image/team/unavailableimage.png"
                                name="Other Members"
                                title="Associate"
                                details={<><p>Not there.</p></>}
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default TeamMemberProfilePage;