import React from 'react';
// Importing page sections.
import AboutDetails from '../../components/about/AboutDetails';
import OurTeam from '../../components/about/team/OurTeam';

const AboutPage = () => {
    return(
        <div>
            <AboutDetails />
            <OurTeam />
        </div>
    );
}

export default AboutPage;