import React from 'react';
import OurServices from '../../components/services/OurServices';
import OurClients from '../../components/clients/OurClients';

const ServicesPage = () => {
    return(
        <div>
            <OurServices />
            <OurClients />
        </div>
    );
}

export default ServicesPage;