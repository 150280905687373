import React, { useState } from 'react';
// import axios from 'axios';
import '../css/servicedropdown.css';

const InquiryForm = () => {
    const [fields, setFields] = useState({
        firstName: "",
        surName: "",
        emailAddress: "",
        telephone: "",
        companyName: "",
        messageSubject: "",
        serviceInquired: "",
        message: "",
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFields({ ...fields, [name]: value });
	}

    const handleSubmit = async (e) => {
        e.preventDefault();
        // // openModal();
        // axios.post('https://www.wca.ug/api/send-feedback', {
        //     fname: fields.firstName,
        //     lname: fields.surName,
        //     email: fields.emailAddress,
        //     phonecontact: fields.telephone,
        //     companyname: fields.companyName,
        //     topic: fields.messageSubject,
        //     topicservice: fields.serviceInquired,
        //     messagebody: fields.message,
		// 	subtime: new Date().toLocaleTimeString(),
		// 	subdate: new Date().toLocaleDateString()
        // }).then((response) => {
        //     // setResponse(response.data)
        //     console.log(response.data);
        //     setFields({
        //         firstName: "",
        //         surName: "",
        //         emailAddress: "",
        //         telephone: "",
        //         companyName: "",
        //         messageSubject: "",
        //         serviceInquired: "",
        //         message: ""
        // })
        //     // e.target.reset();
        // }).catch((err) => console.log(err))
        console.log(fields);
    };

    const options = [
        { value: '', label: '--Select the service you are inquiring about--', disabled: true, hidden: true, selected: true },
        { value: "Civil and Criminal Litigation", label: "Civil and Criminal Litigation" },
        { value: "Commercial Law", label: "Commercial Law" },
        { value: "Land Transactions", label: "Land Transactions" },
        { value: "Arbitration, Mediation and Conciliation", label: "Arbitration, Mediation and Conciliation" },
        { value: "Property Management", label: "Property Management" },
        { value: "Human rights defense and advocacy", label: "Human rights defense and advocacy" },
        { value: "Constitutional law and practice", label: "Constitutional law and practice" },
        { value: "Public Interest Litigation", label: "Public Interest Litigation" },
        { value: "All / Any the services", label: "All / Any the above" }
    ];

    return(
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form contact-form">
                <div id="sendmessage">Your message has been sent. Thank you!</div>
                <div id="errormessage"></div>
                <form role="form" className="contactForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="fname"
                            name="firstName"
                            value={fields.firstName}
                            className="form-control"
                            placeholder="First Name"
                            onChange={handleChange}
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="surname"
                            name="surName"
                            value={fields.surName}
                            className="form-control"
                            placeholder="Surname"
                            onChange={handleChange}
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="emailAddress"
                            value={fields.emailAddress}
                            className="form-control"
                            placeholder="Email Address"
                            onChange={handleChange}
                            data-rule="email"
                            data-msg="Please enter a valid email"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="telephone"
                            name="telephone"
                            value={fields.telephone}
                            className="form-control"
                            placeholder="Telephone number"
                            onChange={handleChange}
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="orgname"
                            name="companyName"
                            value={fields.companyName}
                            className="form-control"
                            placeholder="Company / Organization name (OPTIONAL)"
                            onChange={handleChange}
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="subject"
                            name="messageSubject"
                            value={fields.messageSubject}
                            className="form-control"
                            placeholder="Subject of the Question / Inquiry"
                            onChange={handleChange}
                            data-rule="minlen:4"
                            data-msg="Please enter at least 8 chars of subject"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className='form-group'>
                        <select id="serviceinquired" name="serviceInquired" value={fields.serviceInquired} onChange={handleChange} className='textinput select-input form-control'>
                            {options.map((value, index) => (
                                <option
                                disabled={value.disabled}
                                hidden={value.hidden}
                                selected={value.selected}
                                value={value.value}
                                key={index}
                                className="dropdown-menu dropdown-item"
                                style={{backgroundColor: '#d9dadb'}}>
                                    {value.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <textarea
                            id="message"
                            name="message"
                            value={fields.message}
                            // cols="30"
                            rows="7"
                            className="form-control"
                            placeholder="Type your question / inquiry here..."
                            onChange={handleChange}
                            data-rule="required"
                            data-msg="Please write something for us"
                            required
                        />
                        <div className="validation"></div>
                    </div>
                    <div className="text-center"><button type="submit">Submit Question</button></div>
                </form>
            </div>
        </div>
    );
}

export default InquiryForm;