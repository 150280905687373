import React from 'react';

const ServiceItem = (props) => {
    return(
        <div className="col-md-3 col-sm-4 col-xs-12">
            <div className="about-move">
                <div className="services-details">
                    <div className="single-services">
                        <a className="services-icon" href="#">
                            <i className={"fa "+props.icon}></i>
                        </a>
                        <h4>{props.name}</h4>
                        <p>
                            A brief description of the service.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;