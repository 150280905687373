import React from 'react';
import AboutWCA from './../../resources/images/about/about-walyemera.jpg';

const AboutDetails = () => {
    return(
        <div id="about" className="about-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <h2>About WCA</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* single-well start*/}
                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ textAlign: "center" }}>
                        <div className="well-left">
                            <div className="single-well">
                                <img src={AboutWCA} style={{ height: 300 }} alt="Walyemera & Co. Advocates" />
                            </div>
                        </div>
                    </div>
                    {/* single-well end*/}
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-middle">
                            <div className="single-well">
                                <a href="#">
                                    <h4 className="sec-head">Who Are We?</h4>
                                </a>
                                <p>
                                    We are a Law firm that has qualified, professional, skilled and result-oriented Advocates. In our law office, we have a conviction that states,
                                </p>
                                <p>
                                    "<b><i>We can practice law in Uganda and beyond and are of service to individuals, business entities, communities and government organizations.</i></b>"
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* End col*/}
                </div>
            </div>
        </div>
    );
}

export default AboutDetails;