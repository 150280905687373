import PageFormat from './pages/PageFormat';
// import logo from './logo.svg';
// @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');
import './resources/css/MainStyle.css';
import './resources/css/responsive.css';
// import './resources/css/style.css';
import './resources/lib/bootstrap/js/bootstrap.js';
// import './resources/lib/bootstrap/css/bootstrap.css';

function App() {
  return (
    <>
      <PageFormat />
    </>
  );
}

export default App;
