import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [fields, setFields] = useState({
        firstName: "",
        surName: "",
        emailAddress: "",
        telephone: "",
        companyName: "",
        messageSubject: "",
        message: ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFields({ ...fields, [name]: value });
	}

    const handleSubmit = async (e) => {
        e.preventDefault();
        // // openModal();
        axios.post('http://127.0.0.1:8000/api/feedback', {
            firstname: fields.firstName,
            surname: fields.surName,
            emailaddress: fields.emailAddress,
            telephone: fields.telephone,
            organization: fields.companyName,
            subject: fields.messageSubject,
            message: fields.message,
			time: new Date().toLocaleTimeString(),
			date: new Date().toLocaleDateString()
        }).then((response) => {
            // setResponse(response.data)
            console.log(response.data);
            setFields({
                firstName: "",
                surName: "",
                emailAddress: "",
                telephone: "",
                companyName: "",
                messageSubject: "",
                message: ""
        })
            // e.target.reset();
        }).catch((err) => console.log(err))
        // alert(fields);
        // console.log(fields);
    };

    return(
        <div className="form contact-form">
            <div id="sendmessage">Your message has been sent. Thank you!</div>
            <div id="errormessage"></div>
            <form role="form" className="contactForm" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        id="fname"
                        name="firstName"
                        value={fields.firstName}
                        className="form-control"
                        placeholder="First Name"
                        onChange={handleChange}
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="surname"
                        name="surName"
                        value={fields.surName}
                        className="form-control"
                        placeholder="Surname"
                        onChange={handleChange}
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        name="emailAddress"
                        value={fields.emailAddress}
                        className="form-control"
                        placeholder="Email Address"
                        onChange={handleChange}
                        data-rule="email"
                        data-msg="Please enter a valid email"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="telephone"
                        name="telephone"
                        value={fields.telephone}
                        className="form-control"
                        placeholder="Telephone number"
                        onChange={handleChange}
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="orgname"
                        name="companyName"
                        value={fields.companyName}
                        className="form-control"
                        placeholder="Company / Organization name (OPTIONAL)"
                        onChange={handleChange}
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="subject"
                        name="messageSubject"
                        value={fields.messageSubject}
                        className="form-control"
                        placeholder="Message / Inquiry subject"
                        onChange={handleChange}
                        data-rule="minlen:4"
                        data-msg="Please enter at least 8 chars of subject"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="form-group">
                    <textarea
                        id="message"
                        name="message"
                        value={fields.message}
                        // cols="30"
                        rows="7"
                        className="form-control"
                        placeholder="Type your message / inquiry here..."
                        onChange={handleChange}
                        data-rule="required"
                        data-msg="Please write something for us"
                        required
                    />
                    <div className="validation"></div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
        </div>
    );
}

export default ContactForm;