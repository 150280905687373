import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';
// Importing page sections.
import NavBar from '../components/navigate/NavBar';
import Footer from '../components/footer/Footer';
// Importing pages.
import HomePage from './HomePage';
import AboutPageContainer from './about/AboutPageContainer';
import AboutPage from './about/AboutPage';
import TeamMemberProfilePage from './about/TeamMemberProfilePage';
import ServicesPageContainer from './services/ServicesPageContainer';
import ServicesPage from './services/ServicesPage';
import InquiriesPage from './InquiresPage';
import ContactUsPage from './ContactUsPage';

const PageFormat = () => {
    return(
        <div>
            <NavBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="about-us" element={<AboutPageContainer />}>
                    <Route path="" element={<AboutPage />} />
                    <Route path="team/:keyId" element={<TeamMemberProfilePage />} />
                </Route>
                <Route path="our-services" element={<ServicesPageContainer />}>
                    <Route path="" element={<ServicesPage />} />
                    {/* <Route path=":keyId" element={<ServicesDetailsPage />} /> */}
                    {/* <Route path="faqs" element={<FrequentQuestions />} /> */}
                    {/* <Route path="service-request" element={<RequestService />} /> */}
                </Route>
                <Route path="inquiries" element={<InquiriesPage />} />
                {/* <Route path="our-portfolio" element={<PortfolioPage />} /> */}
                <Route path="contact-us" element={<ContactUsPage />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default PageFormat;